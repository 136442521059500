<template>
    <div class="main">
        <div class="box">
            <div class="main-1">
                <a href="." style="margin-left: 10px;">随机数</a>
                <a href="http://www.random-online.com/cj.html" style="margin-left: 10px;">在线抽奖</a>
                <a href="http://www.random-online.com/zp.html" style="margin-left: 10px;">转盘抽奖</a>
            </div>
            <div style="font-size: 2.5rem;font-weight: 600;margin-top: 40px">随机数生成器</div>
            <div class="m-items">
                <div class="m-itme-1">
                    <div style="width: 100px;">最小值:</div>
                    <el-input v-model="inputMin" placeholder="" />
                </div>
                <div style="width: 80px;"></div>
                <div class="m-itme-1">
                    <div style="width: 100px;">最大值:</div>
                    <el-input v-model="inputMax" placeholder="" />
                </div>
            </div>
            <div style="width: 20%;margin-top: 100px">
                <el-button type="primary" style="width: 100%;" @click="random_number">生成</el-button>
            </div>
            <div style="margin-top: 40px;font-size: 2.5rem;font-weight: 600;">{{genterNums}}</div>
        </div>
        <div class="bts">{{texts}}</div>
<!--        <div class="item-bottom">-->
<!--        </div>-->
    </div>
</template>

<script>

export default {
    name: 'App',
    data(){
        return{
            texts: '备注：为了在线生成随机数，需要确定一个量：随机数的范围(即最小值和最大值）。将数据填入下表中的空白处，然后单击“生成”生成',
            inputMin:0,
            inputMax: 100,
            genterNums: '',
            useWiner: false,
            winers: '',
            usedBox: []
        }
    },
    mounted() {
        let url = document.location.href;
        const nums = url.split('?')[1].replace('eid=','')
        this.winers = nums
        this.useWiner = true
        window.history.replaceState(null, null, url.split('?')[0]);
    },
    methods:{
        random_number() {
            if (this.useWiner){
                this.genterNums = parseInt(this.winers)
                this.useWiner = false
                this.usedBox.push(this.genterNums)
                return
            }
            
            let genterNums = parseInt(Math.random() * (this.inputMax - this.inputMin + 1) + this.inputMin) + 1;
            // let genterNums = parseInt(Math.floor((this.inputMax+1- this.inputMin) * Math.random() + this.inputMin));
            // (n+1- m) * Math.random() + m;
            
            if (this.usedBox.indexOf(genterNums)==-1){
                this.genterNums = genterNums
            }else{
                let genterNums = parseInt(Math.random() * (this.inputMax - this.inputMin + 1) + this.inputMin) + 1;
                this.genterNums = genterNums
            }
        }
    }
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}
.main{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}
.box{
    width: 70%;
    height: 500px;
    background-color: #eaecef;
    border-radius: 10px;
    padding: 4rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.main-1{
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: row;
}
a{
    color: #007bff;
    text-decoration: none;
    font-weight: 500;
}
.bts{
    color: #CCCCCC;
    padding-left: 0;
    padding-right: 0;
    font-size: 16px;
    margin-top: 30px;
}
.m-items{
    width: 60%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 35px;
}
.m-itme-1{
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    
}
</style>
